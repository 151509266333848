import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { DateTime } from 'luxon';

import {
  addCompetitor,
  fetchCompetitors,
  deleteCompetitor,
} from '../redux/actions';
import { integrations } from './competitor/Integrations';

const mapping = integrations.reduce((acc, int) => {
  return Object.assign(acc, {
    [int.name.split(' ').join('').toLowerCase()]: int.img,
  });
}, {});

const mapStateToProps = (state, ownProps) => {
  return { competitors: state.data.competitors, org: state.org };
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '29%',
    margin: '20px',
    height: '300px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function NewCompetitors(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalStyle] = React.useState(getModalStyle);

  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    props.fetchCompetitors();
  }, []);

  const addCompetitor = () => {
    setAdding(true);
    setOpen(false);
    props.addCompetitor(name, website).then(() => {
      setAdding(false);
      setName('');
      setWebsite('');
      toast('added');
    });
  };

  const {
    competitors = [],
    org: { stripe_subscription_price_id, stripe_customer_id } = {},
  } = props;

  const handleUpgrade = () => {
    if (stripe_customer_id) {
      fetch({
        method: 'post',
        url: `/billingPortalSession`,
        data: {
          scid: stripe_customer_id,
          redirect: window.location.href,
        },
      }).then(session => {
        if (session.data && session.data.url) {
          window.location = session.data.url;
        }
      });
    }
  };

  const {
    REACT_APP_FREE_PRICE_ID = 'price_HL28ynbYCEJ6XG',
    REACT_APP_BASIC_PRICE_ID = 'price_HKm3W5xIz5hMYU',
    REACT_APP_PRO_PRICE_ID = 'price_HKm49qIES6bXB6',
    REACT_APP_BUSINESS_PRICE_ID = 'price_HKm4qaL7gFPet0',
  } = process.env;

  const per = {
    [REACT_APP_FREE_PRICE_ID]: 1,
    [REACT_APP_BASIC_PRICE_ID]: 5,
    [REACT_APP_PRO_PRICE_ID]: 20,
    [REACT_APP_BUSINESS_PRICE_ID]: -1,
  };

  const allowed = per[stripe_subscription_price_id];

  const competitorsToDisplay = [...competitors];

  const canAdd = allowed === -1 || allowed > competitorsToDisplay.length;

  return (
    <div
      style={{
        padding: '30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            fontSize: '32px',
          }}
        >
          Competitors
        </div>
        <div>
          {true ? (
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Add Competitor
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleUpgrade}>
              Upgrade Plan to Add Competitor
            </Button>
          )}
        </div>
      </div>
      <div>
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'white',
            marginBottom: '5px',
            height: '45px',
          }}
        >
          <div style={{ width: '300px', paddingLeft: '10px' }}>Name</div>
          <div style={{ width: '200px' }}>Configured Integrations</div>
          <div style={{ width: '150px' }}>Updates</div>
          <div style={{ width: '150px' }}>Last Update</div>
          <div style={{}}></div>
        </Paper>
        {competitors.map(comp => {
          const { configured_integrations: configuredIntegrations = [] } = comp;
          return (
            <Paper
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                marginBottom: '15px',
                height: '65px',
              }}
              key={comp.id}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      height: '25px',
                      width: '25px',
                      borderRadius: '25px',
                      backgroundImage: `url(${comp.image})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  />
                  <div style={{ fontSize: '18px' }}>
                    <Link
                      to={`/competitors/${comp.name
                        .split(' ')
                        .join('-')
                        .toLowerCase()}`}
                    >
                      {comp.name}
                    </Link>
                  </div>
                </div>
                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {configuredIntegrations.map((int, i) => {
                    return (
                      <div
                        key={int}
                        style={{
                          height: '25px',
                          width: '25px',
                          borderRadius: '25px',
                          backgroundImage: `url(${
                            mapping[int.split(' ').join('').toLowerCase()]
                          })`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          marginRight: `${8}px`,
                          zIndex: 30,
                        }}
                      />
                    );
                  })}
                </div>
                <div style={{ width: '150px' }}>
                  {comp.notif_updates && comp.notif_updates !== '0'
                    ? comp.notif_updates
                    : ''}
                </div>
                <div style={{ width: '150px' }}>
                  {comp.last_notif_at
                    ? DateTime.fromISO(comp.last_notif_at).toFormat(
                        'LLL dd h:mm a'
                      )
                    : ''}
                </div>
              </div>
              <div
                style={{
                  marginRight: '30px',
                }}
              >
                <Link
                  to={`/competitors/${comp.name
                    .split(' ')
                    .join('-')
                    .toLowerCase()}`}
                >
                  <Button variant="outlined" color="primary">
                    Edit
                  </Button>
                </Link>
              </div>
            </Paper>
          );
        })}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Add a new competitor</h2>
          <TextField
            required
            id="outlined-required"
            label="Name"
            defaultValue="Hello World"
            variant="outlined"
            style={{ marginTop: '20px', marginBottom: '20px' }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="Website"
            defaultValue="Hello World"
            variant="outlined"
            style={{ marginTop: '20px', marginBottom: '20px' }}
            value={website}
            onChange={e => setWebsite(e.target.value)}
          />
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Button variant="contained" color="primary" onClick={addCompetitor}>
              Add Competitor
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps, { fetchCompetitors, addCompetitor })(
  NewCompetitors
);
