import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { toast } from 'react-toastify';

import {
  createCompetitorIntegration,
  testCompetitorIntegration,
} from '../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function Stock(props) {
  const {
    competitor,
    integration,
    configuredIntegration = {},
    channels = [],
  } = props;
  const { id } = competitor;

  const { id: existingId, settings: { ticker: existingTicker = '' } = {} } =
    configuredIntegration;

  const [ticker, setTicker] = useState(existingTicker);

  useEffect(() => {
    setTicker(existingTicker);
  }, [existingTicker]);

  const addCompetitorIntegration = () => {
    const deSlug = integration
      .split('-')
      .map(s => s[0].toUpperCase() + s.slice(1))
      .join('');
    props
      .createCompetitorIntegration(id, {
        name: deSlug,
        settings: {
          ticker,
        },
      })
      .then(() => {
        toast('done');
      })
      .catch(() => {
        toast('error');
      });
  };

  // const testCompetitorIntegration = () => {
  //   props.testCompetitorIntegration(id, existingId).then(() => toast("tested"))
  // }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            margin: '30px',
          }}
        >
          <div>Set the stock ticker for this competitor</div>
          <TextField
            required
            id="outlined-required"
            label="Ticker"
            defaultValue="Hello World"
            variant="outlined"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              width: '300px',
            }}
            value={ticker}
            onChange={e => setTicker(e.target.value)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <div style={{ marginRight: '20px' }}>
              <Button variant="contained" onClick={addCompetitorIntegration}>
                {existingId ? 'Update' : 'Add'}
              </Button>
            </div>
            {/* <div>
                <Button disabled={!existingId || !channels.length} variant="contained" onClick={testCompetitorIntegration}>Test</Button>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, {
  createCompetitorIntegration,
  testCompetitorIntegration,
})(Stock);
