import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
// import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsIcon from '@material-ui/icons/Settings';

import { fetch, API_BASE_URL } from '../redux/actions';
import {
  addCompetitor,
  fetchCompetitors,
  deleteCompetitor,
} from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  console.log('in competitors mapStateToProps', state);
  return { competitors: state.data.competitors, org: state.org };
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '29%',
    margin: '20px',
    height: '300px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function Competitors(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalStyle] = React.useState(getModalStyle);

  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    props.fetchCompetitors();
  }, []);

  const addCompetitor = () => {
    setAdding(true);
    setOpen(false);
    props.addCompetitor(name, website).then(() => {
      setAdding(false);
      setName('');
      setWebsite('');
      toast('added');
    });
  };

  const deleteCompetitor = id => {
    if (id) {
      props.deleteCompetitor(id);
    }
  };

  const editCompetitor = competitor => {
    const { id, name } = competitor;
    const slug = name.split(' ').join('-').toLowerCase();
    props.history.push(`competitors/${slug}`);
  };

  const {
    competitors = [],
    org: { stripe_subscription_price_id, stripe_customer_id } = {},
  } = props;

  const handleUpgrade = () => {
    if (stripe_customer_id) {
      fetch({
        method: 'post',
        url: `${API_BASE_URL}/billingPortalSession`,
        data: {
          scid: stripe_customer_id,
          redirect: window.location.href,
        },
      }).then(session => {
        if (session.data && session.data.url) {
          window.location = session.data.url;
        }
      });
    }
  };

  const {
    REACT_APP_FREE_PRICE_ID = 'price_HL28ynbYCEJ6XG',
    REACT_APP_BASIC_PRICE_ID = 'price_HKm3W5xIz5hMYU',
    REACT_APP_PRO_PRICE_ID = 'price_HKm49qIES6bXB6',
    REACT_APP_BUSINESS_PRICE_ID = 'price_HKm4qaL7gFPet0',
  } = process.env;

  const per = {
    [REACT_APP_FREE_PRICE_ID]: 1,
    [REACT_APP_BASIC_PRICE_ID]: 5,
    [REACT_APP_PRO_PRICE_ID]: 20,
    [REACT_APP_BUSINESS_PRICE_ID]: -1,
  };

  const allowed = per[stripe_subscription_price_id];

  const competitorsToDisplay = [...competitors];

  if (adding)
    competitorsToDisplay.push({
      loader: true,
      name,
      website,
    });

  const canAdd = allowed === -1 || allowed > competitorsToDisplay.length;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '30px',
        }}
      >
        <h2>Competitors</h2>
        {canAdd ? (
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Add Competitor
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleUpgrade}>
            Upgrade Plan to Add Competitor
          </Button>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {competitorsToDisplay
          .sort((a, b) => {
            return a.name < b.name ? -1 : 1;
          })
          .map(competitor => {
            const img =
              competitor.image ||
              'https://www.ajactraining.org/wp-content/uploads/2019/09/image-placeholder.jpg';
            return (
              <div
                className="competitor-card-wrapper"
                style={{
                  width: '32%',
                  marginBottom: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: '350px',
                }}
              >
                <div
                  className="competitor-card"
                  style={{
                    position: 'relative',
                    width: '95%',
                    height: '160px',
                  }}
                >
                  {competitor.loader ? (
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        marginTop: '20px',
                        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.1)',
                        borderRadius: '25px',
                        display: 'flex',
                        width: '120px',
                        height: '120px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => editCompetitor(competitor)}
                    >
                      <CircularProgress size={75} color="inherit" />
                    </div>
                  ) : (
                    <div
                      style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        marginTop: '20px',
                        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.1)',
                        borderRadius: '25px',
                        cursor: 'pointer',
                      }}
                      onClick={() => editCompetitor(competitor)}
                    >
                      <div
                        className="competitor-card-image"
                        style={{
                          height: '120px',
                          width: '120px',
                          borderRadius: '25px',
                          backgroundImage: `url(${img})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '25px',
                      height: '100%',
                      width: '85%',
                      float: 'right',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => editCompetitor(competitor)}
                  >
                    <div
                      style={{
                        marginLeft: '80px',
                        marginRight: '10px',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <div>{competitor.name}</div>
                        <div className="competitor-card-link">
                          <a
                            onClick={e => e.stopPropagation()}
                            target="_blank"
                            href={competitor.website}
                          >
                            {competitor.website}
                          </a>
                        </div>
                        <div
                          className="competitor-card-about"
                          style={{ fontSize: '12px' }}
                        >
                          {competitor.description}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
            // return (
            //   <Card className={classes.root} key={competitor.id}>
            //     <CardHeader
            //       action={
            //         <IconButton aria-label="settings" onClick={() => editCompetitor(competitor.id)}>
            //           <EditIcon/>
            //         </IconButton>
            //       }
            //       title={competitor.name}
            //       subheader={
            //         <a href={competitor.website}>
            //           {competitor.website}
            //         </a>
            //       }
            //     />
            //     <CardMedia
            //       className={classes.media}
            //       image={competitor.image}
            //       title={`${competitor.name} logo`}
            //     />
            //     <CardContent>
            //       <Typography variant="body2" color="textSecondary" component="p">
            //         {competitor.description}
            //       </Typography>
            //     </CardContent>
            //     <CardActions disableSpacing>
            //       <IconButton onClick={() => deleteCompetitor(competitor.id)}>
            //         <DeleteIcon/>
            //       </IconButton>
            //     </CardActions>
            //   </Card>
            // );
          })}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Add a new competitor</h2>
          <TextField
            required
            id="outlined-required"
            label="Name"
            defaultValue="Hello World"
            variant="outlined"
            style={{ marginTop: '20px', marginBottom: '20px' }}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <TextField
            required
            id="outlined-required"
            label="Website"
            defaultValue="Hello World"
            variant="outlined"
            style={{ marginTop: '20px', marginBottom: '20px' }}
            value={website}
            onChange={e => setWebsite(e.target.value)}
          />
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Button variant="contained" color="primary" onClick={addCompetitor}>
              Add Competitor
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps, {
  addCompetitor,
  fetchCompetitors,
  deleteCompetitor,
})(Competitors);
