import React, { Component, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Home from './components/home';
import SignUp from './components/signup';
import SignIn from './components/signin';
import Sidebar from './components/Sidebar';
import OrgInfo from './components/OrgInfo';
import SignOut from './components/SignOut';
import NewCompetitor from './components/NewCompetitor';
import Integration from './components/Integration';
import Oauth from './components/Oauth';
import Notifications from './components/Notifications';
import Billing from './components/Billing';
import BillingPlans from './components/BillingPlans';

import NewCompetitors from './components/NewCompetitors';
import Competitors from './components/Competitors';
import Verify from './components/Verify';

import * as routes from './constants/routes';
import ScrollToTop from './common/scrollToTop';

import { fetchUser } from './redux/actions';

import { history } from './redux/store';

import './App.css';

const mapStateToProps = (state, ownProps) => {
  return { userId: state.user.id, loading: state.user.loading };
};

function App(props) {
  const { userId, loading = true } = props;

  useEffect(() => {
    props.fetchUser();
  }, []);

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={150} color="inherit" />
      </div>
    );

  return (
    <div className="App">
      <Router history={history}>
        {userId ? <MainContentRouter /> : <LoggedOutRouter />}
      </Router>
    </div>
  );
}

function LoggedOutRouter() {
  return (
    <Switch>
      <Route exact path={routes.SIGNUP} component={SignUp} />
      <Route exact path={routes.SIGNIN} component={SignIn} />
      <Route
        render={props => {
          const redirectTo = encodeURIComponent(props.location.pathname);
          return <Redirect to={`${routes.SIGNIN}?redirect=${redirectTo}`} />;
        }}
      />
    </Switch>
  );
}

function MainContentRouter() {
  return (
    <div className="main">
      <Sidebar />
      <div className="content" style={{ backgroundColor: '#f1f1fe' }}>
        <Switch>
          <Route exact path={routes.COMPETITORS} component={NewCompetitors} />
          <Route
            exact
            path={`${routes.COMPETITORS}/:id`}
            component={NewCompetitor}
          />
          <Route
            exact
            path={`${routes.COMPETITORS}/:id/:integration`}
            component={NewCompetitor}
          />
          <Route
            exact
            path={`${routes.COMPETITORS}/:id/:integration/:int`}
            component={NewCompetitor}
          />
          <Route exact path={routes.NOTIFICATIONS} component={Notifications} />
          <Route exact path={routes.ACCOUNT} component={OrgInfo} />
          <Route exact path={routes.BILLING} component={Billing} />
          <Route exact path={routes.BILLING} component={BillingPlans} />
          <Route exact path={routes.SETTINGS}>
            settings
          </Route>
          <Route exact path={routes.OAUTH} component={Oauth} />
          <Route exact path={routes.VERIFY} component={Verify} />
          <Route exact path={routes.HELP}>
            help
          </Route>
          <Route exact path={routes.SIGNOUT} component={SignOut} />
          <Route
            render={() => {
              return <Redirect to={routes.COMPETITORS} />;
            }}
          />
        </Switch>
      </div>
      <ToastContainer autoClose={2000} hideProgressBar={true} />
    </div>
  );
}

export default connect(mapStateToProps, { fetchUser })(App);
