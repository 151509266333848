import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import todos from './todos';
import user from './user';
import data from './data';
import org from './org';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    data,
    org,
  });

export default createRootReducer;
