import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { fetch, API_BASE_URL } from '../redux/actions';
import { renderFuncs } from './CompetitorOverview';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function LatestUpdate(props) {
  const { integrationId: id, competitorId: compId } = props;
  const [loading, setLoading] = useState(true);
  const [updates, setUpdates] = useState([]);
  useEffect(() => {
    if (id) {
      fetch({
        method: 'get',
        url: `${API_BASE_URL}/competitors/${compId}/integrations/${id}/data`,
      }).then(response => {
        setLoading(false);
        setUpdates(response.data.data);
      });
    } else {
      setUpdates([]);
    }
  }, [id, compId, props.key]);

  if (loading) return <>loding</>;
  console.log(updates);
  const update = updates[0];

  if (!update) return <>missing</>;

  const Func =
    renderFuncs[update.integration_type.toLowerCase()] ||
    (({ update }) => <div>{update.integration_type}</div>);

  return (
    <div key={props.key}>
      <Func update={update} />
    </div>
  );
}

export default connect(mapStateToProps, {})(LatestUpdate);
