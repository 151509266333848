import {
  FETCH_COMPETITORS_SUCCESS,
  CREATE_COMPETITOR_SUCCESS,
  DELETE_COMPETITOR_SUCCESS,
  FETCH_COMPETITOR_SUCCESS,
  FETCH_COMPETITOR_INTEGRATIONS_SUCCESS,
  DELETE_NOTIFICATION_CHANNEL_SUCCESS,
  FETCH_NOTIFICATION_CHANNELS_SUCCESS,
  CREATE_COMPETITOR_INTEGRATION_SUCCESS,
} from '../actionTypes';

const initialState = {
  orgs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPETITORS_SUCCESS: {
      return {
        ...state,
        competitors: action.payload.competitors,
      };
    }
    case CREATE_COMPETITOR_SUCCESS: {
      const { competitor } = action.payload;
      const { competitors = [] } = state;
      return {
        ...state,
        competitors: [...competitors, competitor],
      };
    }
    case DELETE_COMPETITOR_SUCCESS: {
      const { id } = action.payload;
      const { competitors = [] } = state;
      return {
        ...state,
        competitors: competitors.filter(c => c.id !== id),
      };
    }
    case FETCH_COMPETITOR_SUCCESS: {
      const { competitor } = action.payload;
      const { competitors = [] } = state;
      return {
        ...state,
        competitors: [...competitors, ...competitor],
      };
    }
    case FETCH_COMPETITOR_INTEGRATIONS_SUCCESS: {
      const { competitorIntegrations } = action.payload;
      const { competitorIntegrations: existingCompetitorIntegrations = [] } =
        state;
      const merged = Object.assign(
        existingCompetitorIntegrations.reduce((acc, int) => {
          return Object.assign(acc, {
            [int.id]: int,
          });
        }, {}),
        competitorIntegrations.reduce((acc, int) => {
          return Object.assign(acc, {
            [int.id]: int,
          });
        }, {})
      );
      return {
        ...state,
        competitorIntegrations: Object.keys(merged).map(id => merged[id]),
      };
    }
    case CREATE_COMPETITOR_INTEGRATION_SUCCESS: {
      const { competitorIntegration } = action.payload;
      const { competitorIntegrations: existingCompetitorIntegrations = [] } =
        state;
      return {
        ...state,
        competitorIntegrations: [
          ...existingCompetitorIntegrations.filter(
            ci => ci.id !== competitorIntegration.id
          ),
          competitorIntegration,
        ],
      };
    }
    case DELETE_NOTIFICATION_CHANNEL_SUCCESS: {
      const { id } = action.payload;
      const { notificationChannels = [] } = state;
      return {
        ...state,
        notificationChannels: notificationChannels.filter(c => c.id !== id),
      };
    }
    case FETCH_NOTIFICATION_CHANNELS_SUCCESS: {
      const { notificationChannels } = action.payload;
      const { notificationChannels: existingNotificationChannels = [] } = state;
      return {
        ...state,
        notificationChannels: [
          ...existingNotificationChannels,
          ...notificationChannels,
        ],
      };
    }
    default:
      return state;
  }
}
