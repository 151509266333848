import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { fetch, API_BASE_URL } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function Oauth(props) {
  const params = new URLSearchParams(props.location.search);
  const foo = params.get('code');

  useEffect(() => {
    if (!foo) return props.history.push(`/notifications`);
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/slack/authorize`,
      data: { code: foo },
    }).then(response => {
      props.history.push(`/notifications`);
    });
  }, []);

  return <div>oauthdsfd - {foo}</div>;
}

export default connect(mapStateToProps, {})(Oauth);
