import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Twitter from './integrations/Twitter';
import Instagram from './integrations/Instagram';
import AppStore from './integrations/AppStore';
import News from './integrations/News';
import SiteTraffic from './integrations/SiteTraffic';
import StatusWatch from './integrations/StatusWatch';
import Stock from './integrations/Stock';
import Reddit from './integrations/Reddit';
import VisualDiff from './integrations/VisualDiff';
import YouTube from './integrations/YouTube';
import Hiring from './integrations/Hiring';
import ComingSoon from './integrations/ComingSoon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import LatestUpdate from './LatestUpdate';

import {
  fetchCompetitorBySlug,
  fetchCompetitorIntegrationsBySlug,
  fetchNotificationChannels,
} from '../redux/actions';

const inner = {
  instagram: ComingSoon,
  twitter: Twitter,
  'app-store': AppStore,
  news: ComingSoon,
  'site-traffic': ComingSoon,
  'status-watch': StatusWatch,
  stock: Stock,
  'visual-diff': VisualDiff,
  youtube: YouTube,
  hiring: Hiring,
  glassdoor: ComingSoon,
  reddit: ComingSoon,
};

const mapStateToProps = (state, ownProps) => {
  const { integration, slug } = ownProps;
  const { data: { competitorIntegrations = [], competitors = [] } = {} } =
    state;
  const deSlug = integration.split('-').join('').toLowerCase();
  const configuredIntegration = competitorIntegrations.find(
    int =>
      int.integration_type.toLowerCase() === deSlug &&
      int.competitor_slug === slug
  );
  const channels = state.data.notificationChannels;
  const competitor = competitors.find(
    c => c.name.split(' ').join('-').toLowerCase() === slug
  );
  return { integration, slug, configuredIntegration, channels, competitor };
};

function Integration(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    props.fetchCompetitorIntegrationsBySlug(props.slug).then(() => {
      setLoading(false);
    });
  }, [props.slug]);
  useEffect(() => {
    props.fetchNotificationChannels();
  }, [props.slug]);
  useEffect(() => {
    if (!props.competitor) {
      props.fetchCompetitorBySlug(props.slug);
    }
  }, [props.slug]);
  const C = inner[props.integration];
  if (loading || !props.competitor)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={75} color="inherit" />
      </div>
    );
  if (!C) {
    props.history.push(`/competitors/${props.slug}`);
    return null;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }} key={props.slug}>
      <div style={{ width: '50%' }}>{<C {...props} />}</div>
      <div key={props.slug}>
        <LatestUpdate
          key={props.slug}
          competitorId={props.competitor?.id}
          integrationId={props.configuredIntegration?.id}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, {
  fetchCompetitorIntegrationsBySlug,
  fetchNotificationChannels,
  fetchCompetitorBySlug,
})(Integration);
