import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { toast } from 'react-toastify';

import {
  createCompetitorIntegration,
  testCompetitorIntegration,
  fetch,
  API_BASE_URL,
} from '../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

function AppStore(props) {
  const {
    competitor,
    integration,
    configuredIntegration = {},
    channels = [],
  } = props;
  const { id } = competitor;

  const {
    id: existingId,
    settings: {
      handle: existingAppId = '',
      icon: existingIcon,
      name: existingName,
    } = {},
  } = configuredIntegration;

  const [appName, setAppName] = useState('');
  const [apps, setApps] = useState([]);
  const [appId, setAppId] = useState();
  const [updated, setUpdated] = useState(false);

  const debouncedAppName = useDebounce(appName, 500);

  const app = apps.find(a => a.trackId === appId);

  useEffect(() => {
    setAppName(existingName);
  }, [existingName]);

  useEffect(() => {
    if (debouncedAppName) {
      fetch({
        method: 'get',
        url: `${API_BASE_URL}/apps/search?q=${debouncedAppName}`,
      }).then(response => {
        const { data } = response;
        if (data && data.results && data.results.length) {
          setApps(data.results.slice(0, 5));
        }
      });
    }
  }, [debouncedAppName]);

  const addCompetitorIntegration = () => {
    const deSlug = integration
      .split('-')
      .map(s => s[0].toUpperCase() + s.slice(1))
      .join('');
    props
      .createCompetitorIntegration(id, {
        name: deSlug,
        settings: {
          handle: app.trackId,
          icon: app.artworkUrl512,
          name: app.trackName,
        },
      })
      .then(() => {
        toast('done');
      })
      .catch(() => {
        toast('error');
      });
  };

  // const testCompetitorIntegration = () => {
  //   props.testCompetitorIntegration(id, existingId).then(() => toast("tested"))
  // }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            margin: '30px',
          }}
        >
          <div>
            {`${
              existingAppId ? 'Update' : 'Set'
            } the app name for this competitor`}
          </div>
          <TextField
            required
            id="outlined-required"
            label="App Name"
            defaultValue="Hello World"
            variant="outlined"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              width: '300px',
            }}
            value={appName}
            onChange={e => {
              setAppName(e.target.value);
              setUpdated(true);
            }}
          />
          <div>
            {existingAppId && !updated ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // cursor: 'pointer',
                  // border: appId === app.trackId ? 'solid 1px black' : 'none'
                }}
                // onClick={() => setAppId(app.trackId)}
              >
                <div>
                  <img width={50} src={existingIcon} />
                </div>
                <div style={{ marginLeft: '20px' }}>
                  <div>{existingName}</div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: !existingName || updated ? 'inherit' : 'none',
            }}
          >
            {apps.map(app => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border: appId === app.trackId ? 'solid 1px black' : 'none',
                  }}
                  onClick={() => setAppId(app.trackId)}
                >
                  <div>
                    <img width={50} src={app.artworkUrl512} />
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    <div>{app.trackName}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <div style={{ marginRight: '20px' }}>
              <Button
                disabled={!appId}
                variant="contained"
                onClick={addCompetitorIntegration}
              >
                {existingId ? 'Update' : 'Add'}
              </Button>
            </div>
            {/* <div>
                <Button disabled={!existingId || !channels.length} variant="contained" onClick={testCompetitorIntegration}>Test</Button>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, {
  createCompetitorIntegration,
  testCompetitorIntegration,
})(AppStore);
