import {
  SIGN_IN_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_STARTED,
  FETCH_USER_ERROR,
  USER_SIGN_OUT,
} from '../actionTypes';

const initialState = {
  loggedIn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGN_IN_SUCCESS: {
      const {
        user: { token },
      } = action.payload;
      return {
        ...state,
        token,
        loggedIn: !!token,
      };
    }
    case FETCH_USER_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        ...user,
        loading: false,
      };
    }
    case FETCH_USER_STARTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_USER_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case USER_SIGN_OUT: {
      return {
        loading: false,
      };
    }
    default:
      return state;
  }
}
