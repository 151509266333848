import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import {
  fetchNotificationChannels,
  deleteNotificationChannel,
} from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return { channels: state.data.notificationChannels };
};

function Notifications(props) {
  // const params = new URLSearchParams(props.location.search);
  // const foo = params.get('code');

  const [loading, setLoading] = useState(true);
  // const [channels, setChannels] = useState([]);

  const { channels } = props;

  useEffect(() => {
    props.fetchNotificationChannels().then(() => setLoading(false));
  }, []);

  const remove = id => {
    props.deleteNotificationChannel(id);
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={75} color="inherit" />
      </div>
    );

  const slack = channels.find(channel => channel.channel_type === 'slack');

  let slackLink = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=972988864340.1128773846979`;

  if (process.env.REACT_APP_SLACK_REDIRECT_URL) {
    slackLink += `&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URL}`;
  } else {
    slackLink += `&redirect_uri=https://local.comp.watch:3000/oauth`;
  }

  return (
    <div>
      <h2
        style={{
          margin: '30px',
        }}
      >
        Notification Settings
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            width: '90%',
          }}
        >
          <div
            style={{
              margin: '30px',
            }}
          >
            <div>Slack</div>
            {slack ? (
              <div>
                <div>
                  A slack integration has been configured for workspace:{' '}
                  {slack.data.team.name}
                </div>
                <div>
                  Updates will be posted to:{' '}
                  {slack.data.incoming_webhook.channel}
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => remove(slack.id)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <a href={slackLink}>
                  <img
                    alt="Add to Slack"
                    height="40"
                    width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                  />
                </a>
              </div>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, {
  deleteNotificationChannel,
  fetchNotificationChannels,
})(Notifications);
