import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function ComingSoon(props) {
  const { integration } = props;

  const name = integration
    .split('-')
    .map(s => s[0].toUpperCase() + s.slice(1))
    .join(' ');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <div
        style={{
          marginBottom: '20px',
        }}
      >
        The {name} integration is coming soon!
      </div>
      <div>Check back soon to see if its launched.</div>
    </div>
  );
}

export default connect(mapStateToProps)(ComingSoon);
