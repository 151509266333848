import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import { fetch, API_BASE_URL } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return { orgId: state.user.org_id };
};

function BillingPlans(props) {
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');

  return (
    <div>
      <h2>Billing</h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            width: '90%',
          }}
        >
          <div>Current Plan: Free</div>
          <button disabled={saving}>Change Plan</button>
        </Paper>
        <Paper
          style={{
            width: '90%',
            marginTop: '30px',
          }}
        >
          <div>Invoice History</div>
        </Paper>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(BillingPlans);
