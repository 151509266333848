import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';

import * as routes from '../constants/routes';
import { signOut } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function SignOut(props) {
  localStorage.removeItem('token');

  useEffect(() => {
    props.signOut();
    props.history.push(routes.SIGNIN);
  }, []);

  return null;
}

export default connect(mapStateToProps, { signOut })(SignOut);
