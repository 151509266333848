export const ADD_TODO = 'ADD_TODO';
export const TOGGLE_TODO = 'TOGGLE_TODO';
export const SET_FILTER = 'SET_FILTER';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_STARTED = 'FETCH_USER_STARTED';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const CREATE_COMPETITOR_SUCCESS = 'CREATE_COMPETITOR_SUCCESS';
export const FETCH_COMPETITORS_SUCCESS = 'FETCH_COMPETITORS_SUCCESS';
export const DELETE_COMPETITOR_SUCCESS = 'DELETE_COMPETITOR_SUCCESS';
export const FETCH_COMPETITOR_SUCCESS = 'FETCH_COMPETITOR_SUCCESS';
export const CREATE_COMPETITOR_INTEGRATION_SUCCESS =
  'CREATE_COMPETITOR_INTEGRATION_SUCCESS';
export const FETCH_COMPETITOR_INTEGRATIONS_SUCCESS =
  'FETCH_COMPETITOR_INTEGRATIONS_SUCCESS';
export const DELETE_NOTIFICATION_CHANNEL_SUCCESS =
  'DELETE_NOTIFICATION_CHANNEL_SUCCESS';
export const FETCH_NOTIFICATION_CHANNELS_SUCCESS =
  'FETCH_NOTIFICATION_CHANNELS_SUCCESS';
export const FETCH_ORG_SUCCESS = 'FETCH_ORG_SUCCESS';
