import { FETCH_ORG_SUCCESS } from '../actionTypes';

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORG_SUCCESS: {
      const { org } = action.payload;
      return {
        ...state,
        ...org,
      };
    }
    default:
      return state;
  }
}
