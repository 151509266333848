import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import CompetitorIntegrationOverview from './CompetitorIntegrationOverview';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { integrations } from './competitor/Integrations';

import {
  createCompetitorIntegration,
  testCompetitorIntegration,
  fetch,
  API_BASE_URL,
} from '../redux/actions';

const mapping = integrations.reduce((acc, int) => {
  return Object.assign(acc, {
    [int.name.split(' ').join('').toLowerCase()]: int.img,
  });
}, {});

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const mapStateToProps = (state, ownProps) => {
  const {
    entity: { id },
  } = ownProps;
  const { data: { competitorIntegrations = [] } = {} } = state;
  const configuredIntegrations = competitorIntegrations.filter(
    int => int.competitor_id === id
  );
  return { configuredIntegrations };
};

const renderHiring = ({ update }) => {
  const newJobs = update.data.jobs.filter(job => job.new);
  if (!newJobs.length) return null;
  const jobs = update.data.firstPull ? newJobs.slice(0, 1) : newJobs;
  return (
    <div>
      {jobs.map(job => {
        return (
          <div>
            {/* <pre>{JSON.stringify(update, null, 2)}</pre> */}
            <div style={{ color: 'black', fontWeight: 600 }}>
              {job.department}
            </div>
            <div style={{ fontWeight: 500 }}>
              <a href={job.absolute_url} target="_blank">
                {job.title}
              </a>
            </div>
            <div style={{ color: 'grey', fontWeight: 300 }}>
              {job.location.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const renderReddit = ({ update }) => {
  return (
    <div>
      {/* <pre>{JSON.stringify(update, null, 2)}</pre> */}
      <div>{update.created_at}</div>
      <>{`new post on the ${update.integration_id} subreddit, ${update.data.title}`}</>
    </div>
  );
};

const renderStock = ({ update }) => {
  return (
    <div>
      <pre>{JSON.stringify(update, null, 2)}</pre>
      {/* <div>{update.created_at}</div> */}
      <>
        {/* {`new post on the ${update.integration_id} subreddit, ${update.data.title}`} */}
      </>
    </div>
  );
};

const renderVisualDiff = ({ update }) => {
  return (
    <div>
      <pre>{JSON.stringify(update, null, 2)}</pre>
      {/* <div>{update.created_at}</div> */}
      <div>
        <img
          style={{ width: '400px' }}
          src={`${API_BASE_URL}/assets?q=${update.data.key}`}
        />
      </div>
    </div>
  );
};

const renderStatus = ({ update }) => {
  return (
    <div>
      {/* <pre>{JSON.stringify(update, null, 2)}</pre> */}
      {/* <div>{update.created_at}</div> */}
      <>{`${update.data.name}`}</>
      <ul>
        {update.data.incident_updates.map(up => {
          return <li>{up.body}</li>;
        })}
      </ul>
    </div>
  );
};

const renderYoutube = ({ update }) => {
  return (
    <div>
      {/* <pre>{JSON.stringify(update, null, 2)}</pre> */}
      {/* <div>{update.created_at}</div> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px',
          }}
        >
          <img
            style={{ width: '150px' }}
            src={update.data.snippet.thumbnails.high.url}
          />
        </div>
        <div>
          <div style={{ color: 'black', fontWeight: 600 }}>
            {update.data.snippet.title}
          </div>
          <div>{update.data.snippet.channelTitle}</div>
          <div>{update.data.snippet.description.substr(0, 147)}...</div>
        </div>
      </div>
    </div>
  );
};

const renderAppStore = ({ update }) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
          }}
        >
          <div
            style={{
              marginRight: '10px',
            }}
          >
            <img
              style={{
                height: '50px',
                border: '1px solid lightgrey',
                borderRadius: '10px',
              }}
              src={update.settings.icon}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ color: 'black', fontWeight: 600 }}>
              {update.settings.name}
            </div>
            <div>Yesterday</div>
          </div>
        </div>
        <div
          style={{
            width: '100px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              color: 'blue',
              backgroundColor: 'lightgrey',
              padding: '5px',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
              fontWeight: 600,
            }}
          >
            <div>UPDATE</div>
          </div>
        </div>
      </div>
      <div>
        {update.data.version} - {update.data.releaseNotes}
      </div>
      {/* <pre>{JSON.stringify(update, null, 2)}</pre> */}
      {/* <div>{update.created_at}</div> */}
    </div>
  );
};

const renderTwitter = ({ update }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'initial',
      }}
    >
      <div style={{ width: '100px' }}>
        <img src={update.data.user.profile_image_url_https} />
      </div>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={{ fontWeight: '600', marginRight: '5px' }}>
            {update.data.user.name}
          </div>
          <div>@{update.data.user.screen_name}</div>
        </div>
        <div>{update.data.full_text}</div>
      </div>
      {/* <pre>{JSON.stringify(update, null, 2)}</pre> */}
      {/* <div>{update.created_at}</div> */}
      {/* <>{`${update.integration_id} tweeted $`}</> */}
    </div>
  );
};

export const renderFuncs = {
  hiring: renderHiring,
  reddit: renderReddit,
  stock: renderStock,
  visualdiff: renderVisualDiff,
  youtube: renderYoutube,
  appstore: renderAppStore,
  twitter: renderTwitter,
  statuswatch: renderStatus,
};

function Competitor(props) {
  const { entity, configuredIntegrations = [] } = props;

  const [updates, setUpdates] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetch({
      method: 'get',
      url: `${API_BASE_URL}/competitors/${entity.id}/integrations/data`,
    }).then(response => {
      const {
        data: { data },
      } = response;
      if (data && data.length) {
        setUpdates(data);
      }
    });
  }, [entity.id]);

  const { name } = entity || {};

  return (
    <div
      style={{
        maxWidth: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          // overflowX: 'scroll',
          // height: '380px',
        }}
      >
        <Timeline align="alternate">
          {updates
            .filter(update => {
              if (update.integration_type === 'Hiring') {
                const newJobs = update.data.jobs.filter(job => job.new);
                return update.data.firstPull ? true : newJobs.length;
              } else {
                return true;
              }
            })
            .map(update => {
              const Func =
                renderFuncs[update.integration_type.toLowerCase()] ||
                (({ update }) => <div>{update.integration_type}</div>);
              if (update.integration_type.toLowerCase() === 'statuswatch') {
                return update.data.incident_updates.map(inc => {
                  return (
                    <TimelineItem key={update.id}>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {moment(inc.created_at).fromNow()}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot>
                          <Avatar
                            alt={update.integration_type}
                            src={mapping[update.integration_type.toLowerCase()]}
                          />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Paper elevation={3} className={classes.paper}>
                          <div>
                            <div>{`${update.data.name}`}</div>
                            <div>{`${inc.body}`}</div>
                          </div>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  );
                });
              } else {
                return (
                  <TimelineItem key={update.id}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        {moment(update.created_at).fromNow()}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot>
                        <Avatar
                          alt={update.integration_type}
                          src={mapping[update.integration_type.toLowerCase()]}
                        />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={classes.paper}>
                        {/* <Typography variant="h6" component="h1">
                          {update.integration_type}
                        </Typography> */}
                        <Func update={update} />
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                );
              }
            })}
        </Timeline>
        {/* {updates.map(update => {
          const Func =
            renderFuncs[update.integration_type.toLowerCase()] ||
            (({ update }) => <div>{update.integration_type}</div>);
          return (
            <div key={update.id}>
              <div>
                <Func update={update} />
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Competitor);
