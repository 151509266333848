import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetch, API_BASE_URL, fetchOrg } from '../redux/actions';

import AlbumIcon from '@material-ui/icons/Album';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsInputComponentOutlinedIcon from '@material-ui/icons/SettingsInputComponentOutlined';

import PositionedPopper from './PositionedPopper';

const mapStateToProps = (state, ownProps) => {
  console.dir(state);
  const { user: { org_id: orgId } = {} } = state;
  const org = state.org && state.org.id === orgId && state.org;
  return {
    orgId,
    org,
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash,
  };
};

function Sidebar(props) {
  // const [selectedItem, setSelectedItem] = useState(window.location.pathname.split("/")[1]);
  const { orgId, org, fetchOrg, pathname } = props;

  useEffect(() => {
    if (!org) {
      fetchOrg(orgId);
    }
  }, []);

  const handleBillingClick = () => {
    if (org.stripe_customer_id) {
      fetch({
        method: 'post',
        url: `${API_BASE_URL}/billingPortalSession`,
        data: {
          scid: org.stripe_customer_id,
          redirect: window.location.href,
        },
      }).then(session => {
        if (session.data && session.data.url) {
          window.location = session.data.url;
        }
      });
    }
  };

  // const setSelected = name => () => {
  //   setSelectedItem(name);
  // }

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src="/logo-test.png" />
      </div>
      <div className="sidebar-things">
        {[
          {
            name: 'Competitors',
            icon: <HomeOutlinedIcon style={{ fontSize: 30 }} />,
          },
          {
            name: 'Notifications',
            icon: <BusinessCenterOutlinedIcon style={{ fontSize: 30 }} />,
          },
          // {
          //   name: 'Account',
          //   icon: (<SettingsOutlinedIcon style={{ fontSize: 30 }} />)
          // },
          // {
          //   name: 'Billing',
          //   icon: (<SettingsInputComponentOutlinedIcon style={{ fontSize: 30 }} />),
          //   onClick: handleBillingClick
          // },
          // {
          //   name: 'Help',
          //   icon: (<HelpOutlineIcon style={{ fontSize: 30 }} />)
          // }
        ].map(opt => {
          const selected =
            pathname.toLowerCase().indexOf(opt.name.toLowerCase()) > -1;
          let className = selected
            ? 'sidebar-item sidebar-item-selected'
            : 'sidebar-item';
          if (opt.onClick) {
            return (
              <div
                key={opt.name}
                style={{ cursor: 'pointer' }}
                className={className}
                onClick={opt.onClick}
              >
                <div className="sidebar-item-icon">{opt.icon}</div>
                <div className="sidebar-item-name">{opt.name}</div>
              </div>
            );
          }
          return (
            <Link
              style={{ textDecoration: 'none', color: 'black' }}
              to={`/${opt.name.toLowerCase()}`}
              key={opt.name}
              // onClick={setSelected(opt.name)}
            >
              <div className={className}>
                <div className="sidebar-item-icon">{opt.icon}</div>
                <div className="sidebar-item-name">{opt.name}</div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="sidebar-bottom">
        <PositionedPopper />
      </div>
    </div>
  );
}

export default connect(mapStateToProps, { fetchOrg })(Sidebar);
