import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import {
  fetchCompetitorBySlug,
  deleteCompetitor,
  fetch,
  API_BASE_URL,
} from '../redux/actions';
import * as routes from '../constants/routes';
import Integrations from './competitor/Integrations';
import CompetitorOverview from './CompetitorOverview';
import Remove from './competitor/Remove';

const mapStateToProps = (state, ownProps) => {
  const slug = ownProps.match.params.id;
  const { data: { competitors = [] } = {} } = state;
  const entity = competitors.find(
    c => c.name.split(' ').join('-').toLowerCase() === slug
  );
  return {
    slug,
    entity,
  };
};

function NewCompetitor(props) {
  const { slug, entity } = props;

  useEffect(() => {
    if (!entity)
      props.fetchCompetitorBySlug(slug).catch(e => {
        props.history.push(`/competitors`);
      });
  }, []);

  if (!entity) return <></>;

  return (
    <div
      style={{
        padding: '30px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: '25px',
              width: '25px',
              borderRadius: '25px',
              backgroundImage: `url(${entity.image})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              marginRight: '10px',
            }}
          />
          <div
            style={{
              fontSize: '32px',
            }}
          >
            {entity ? entity.name : ''}
          </div>
        </div>
        <div>
          <Link
            to={`${routes.COMPETITORS}`}
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <KeyboardBackspaceIcon /> Back
          </Link>
        </div>
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Link to={`${routes.COMPETITORS}/${slug}`}>Overview</Link>
          <Link
            to={`${routes.COMPETITORS}/${slug}/integrations`}
            style={{
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            Integrations
          </Link>
          <Link to={`${routes.COMPETITORS}/${slug}/remove`}>Remove</Link>
        </div>
      </div>
      <hr />
      <div>
        <Switch>
          <Route
            exact
            path={`${routes.COMPETITORS}/:id`}
            render={() => <Overview entity={props.entity} />}
          />
          <Route
            exact
            path={`${routes.COMPETITORS}/:id/integrations`}
            component={Integrations}
          />
          <Route
            exact
            path={`${routes.COMPETITORS}/:id/integrations/:int`}
            component={Integrations}
          />
          <Route
            exact
            path={`${routes.COMPETITORS}/:id/remove`}
            render={routeProps => (
              <Remove entity={props.entity} {...routeProps} />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, { fetchCompetitorBySlug })(
  NewCompetitor
);

function Overview(props) {
  if (!props.entity) return <></>;
  return <CompetitorOverview entity={props.entity} />;
}
