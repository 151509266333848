import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import * as routes from '../constants/routes';
import { fetch, API_BASE_URL, fetchUser } from '../redux/actions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a color="inherit" href="https://comp.watch">
        CompWatch
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignUp(props) {
  const classes = useStyles();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const submit = e => {
    e.preventDefault();
    // return props.signIn(username, password)
    //          .then(() => {
    //             props.fetchUser()
    //             props.history.push(routes.COMPETITORS)
    //           });
    fetch({
      url: `${API_BASE_URL}/users/create`,
      method: 'post',
      data: {
        email: username,
        password: password,
      },
    }).then(response => {
      const body = response.data;
      if (body.error) return alert(body.error);
      localStorage.setItem('token', body.token);
      props.fetchUser();
      props.history.push(routes.COMPETITORS);
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={username}
                onChange={e => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!username || !password}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to={routes.SIGNIN} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default connect(null, { fetch, fetchUser })(SignUp);

// import React, { Component } from 'react';
// import * as routes from '../constants/routes';
// import { Link } from 'react-router-dom';

// import { fetch, API_BASE_URL } from "../redux/actions";

// const INITIAL_STATE = {
//   login: '',
//   password: '',
// };

// class SignUp extends Component {
//   state = { ...INITIAL_STATE };
//   onChange = event => {
//     const { name, value } = event.target;
//     this.setState({ [name]: value });
//   };
//   onSubmit = event => {
//     event.preventDefault();
//     fetch({
//       url: `${API_BASE_URL}/users/create`,
//       method: 'post',
//       data: {
//         email: this.state.login,
//         password: this.state.password
//       },
//     }).then(body => {
//       if (body.error) return alert(body.error);
//       localStorage.setItem('id_token', body.token);
//       this.props.history.push(routes.HOME);
//     });
//   }
//   render() {
//     return (
//       <div id="signup">
//         <form className="form-signin" onSubmit={event => this.onSubmit(event)}>
//           <div className="text-center mb-4">
//             <h1 className="h3 mb-3 font-weight-normal">Join Company Name</h1>
//             <p>The best way to do A, B, and C.</p>
//           </div>

//           <div className="form-label-group">
//             <input
//               type="text"
//               id="inputEmail"
//               className="form-control"
//               name="login"
//               value={this.state.login}
//               onChange={this.onChange}
//               placeholder="Email or Username"
//               required
//               autofocus
//             />
//             <label for="inputEmail">Name</label>
//           </div>

//           <div className="form-label-group">
//             <input
//               type="password"
//               id="inputPassword"
//               className="form-control"
//               placeholder="Password"
//               name="password"
//               value={this.state.password}
//               onChange={this.onChange}
//               required
//             />
//             <label for="inputPassword">Password</label>
//           </div>

//           <button
//             className="btn btn-lg btn-primary btn-block"
//             type="submit"
//             disabled={this.state.isInvalid || this.state.loading}
//           >
//             Sign up
//           </button>
//           {
//             //error && <ErrorMessage error={error} />
//           }
//         </form>
//         <p className="text-center">
//           Already have an account? <Link to={routes.SIGNIN}>Sign In</Link>
//         </p>
//       </div>
//     )
//   }
// }

// export default SignUp;
