import axios from 'axios';

import {
  ADD_TODO,
  TOGGLE_TODO,
  SET_FILTER,
  SET_PORTFOLIO_VALUE,
  SET_PORTFOLIO_BALANCE,
  UPDATE_POSITION,
  FETCH_POSITIONS_SUCCESS,
  FETCH_PORTFOLIO_SUCCESS,
  FETCH_ARTIST_SUCCESS,
  FETCH_ARTISTS_SUCCESS,
  FETCH_TWITTER_FOLLOWER_DATA_SUCCESS,
  FETCH_SPOTIFY_RELEASES_DATA_SUCCESS,
  FETCH_PORTFOLIO_HISTORY_SUCCESS,
  SIGN_IN_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_STARTED,
  FETCH_USER_ERROR,
  USER_SIGN_OUT,
  CREATE_COMPETITOR_SUCCESS,
  FETCH_COMPETITORS_SUCCESS,
  DELETE_COMPETITOR_SUCCESS,
  FETCH_COMPETITOR_SUCCESS,
  CREATE_COMPETITOR_INTEGRATION_SUCCESS,
  FETCH_COMPETITOR_INTEGRATIONS_SUCCESS,
  DELETE_NOTIFICATION_CHANNEL_SUCCESS,
  FETCH_NOTIFICATION_CHANNELS_SUCCESS,
  FETCH_ORG_SUCCESS,
} from './actionTypes';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || ''; //'https://eb2b21b5.ngrok.io'

let nextTodoId = 0;

export const fetch = opts =>
  axios(
    Object.assign(opts, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
  );

export const addTodo = content => ({
  type: ADD_TODO,
  payload: {
    id: ++nextTodoId,
    content,
  },
});

export const toggleTodo = id => ({
  type: TOGGLE_TODO,
  payload: { id },
});

export const setFilter = filter => ({
  type: SET_FILTER,
  payload: { filter },
});

// export const setPortfolioValue = value =>({
//   type: SET_PORTFOLIO_VALUE,
//   payload: {
//     value
//   }
// });

// export const setPortfolioBalance = value => ({
//   type: SET_PORTFOLIO_BALANCE,
//   payload: {
//     value
//   }
// });

// export const updatePosition = (artist, shares) => ({
//   type: UPDATE_POSITION,
//   payload: {
//     artist,
//     shares
//   }
// });

// export const fetchPositionsSuccess = positions => ({
//   type: FETCH_POSITIONS_SUCCESS,
//   payload: { positions }
// });

// export const fetchPortfolioSuccess = portfolio => ({
//   type: FETCH_PORTFOLIO_SUCCESS,
//   payload: { portfolio }
// });

// export const fetchArtistSuccess = artist => ({
//   type: FETCH_ARTIST_SUCCESS,
//   payload: { artist }
// });

export const signInSuccess = user => ({
  type: SIGN_IN_SUCCESS,
  payload: { user },
});

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  payload: { user },
});

export const fetchUserStarted = () => ({
  type: FETCH_USER_STARTED,
});

export const fetchUserError = () => ({
  type: FETCH_USER_ERROR,
});

export const userSignOut = () => ({
  type: USER_SIGN_OUT,
});

export const createCompetitorSuccess = competitor => ({
  type: CREATE_COMPETITOR_SUCCESS,
  payload: { competitor },
});

export const fetchCompetitorsSuccess = competitors => ({
  type: FETCH_COMPETITORS_SUCCESS,
  payload: { competitors },
});

export const deleteCompetitorSuccess = id => ({
  type: DELETE_COMPETITOR_SUCCESS,
  payload: { id },
});

export const fetchCompetitorSuccess = competitor => ({
  type: FETCH_COMPETITOR_SUCCESS,
  payload: { competitor },
});

export const createCompetitorIntegrationSuccess = competitorIntegration => ({
  type: CREATE_COMPETITOR_INTEGRATION_SUCCESS,
  payload: { competitorIntegration },
});

export const fetchCompetitorIntegrationsSuccess = competitorIntegrations => ({
  type: FETCH_COMPETITOR_INTEGRATIONS_SUCCESS,
  payload: { competitorIntegrations },
});

export const deleteNotificationChannelSuccess = id => ({
  type: DELETE_NOTIFICATION_CHANNEL_SUCCESS,
  payload: { id },
});

export const fetchNotificationChannelsSuccess = notificationChannels => ({
  type: FETCH_NOTIFICATION_CHANNELS_SUCCESS,
  payload: { notificationChannels },
});

export const fetchOrgSuccess = org => ({
  type: FETCH_ORG_SUCCESS,
  payload: { org },
});

// export const fetchArtistsSuccess = artists => ({
//   type: FETCH_ARTISTS_SUCCESS,
//   payload: { artists }
// })

// export const fetchFollowerDataSuccess = twitterFollowerData => ({
//   type: FETCH_TWITTER_FOLLOWER_DATA_SUCCESS,
//   payload: { twitterFollowerData }
// })

// export const fetchSpotifyReleasesDataSuccess = spotifyReleasesData => ({
//   type: FETCH_SPOTIFY_RELEASES_DATA_SUCCESS,
//   payload: { spotifyReleasesData }
// })

// export const fetchPortfolioHistorySuccess = portfolioHistory => ({
//   type: FETCH_PORTFOLIO_HISTORY_SUCCESS,
//   payload: { portfolioHistory }
// })

// export const fetchPositions = userId => dispatch => {
//   axios(`${API_BASE_URL}/users/${userId}/positions`)
//     .then(response => {
//       dispatch(fetchPositionsSuccess(response.data))
//     })
// }

// export const fetchPortfolio = userId => dispatch => {
//   axios(`${API_BASE_URL}/users/${userId}/portfolio`)
//     .then(response => {
//       dispatch(fetchPortfolioSuccess(response.data))
//     })
// }

export const signIn = (username, password) => dispatch => {
  return axios({
    method: 'post',
    url: `${API_BASE_URL}/users/login`,
    data: {
      email: username,
      password,
    },
  }).then(response => {
    localStorage.setItem('token', response.data.token);
    dispatch(signInSuccess(response.data));
  });
};

export const fetchUser = userId => dispatch => {
  dispatch(fetchUserStarted());
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/users/current`,
  })
    .catch(e => {
      localStorage.removeItem('token');
      dispatch(fetchUserError());
    })
    .then(response => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch(e => {
      console.error('error after fetch user', e);
    });
};

export const signOut = () => dispatch => {
  console.log('in sign out action');
  localStorage.removeItem('token');
  dispatch(userSignOut());
};

export const addCompetitor = (name, website) => dispatch => {
  return fetch({
    method: 'post',
    url: `${API_BASE_URL}/competitors`,
    data: {
      name,
      website,
    },
  }).then(response => {
    dispatch(createCompetitorSuccess(response.data));
  });
};

export const fetchCompetitors = () => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/competitors`,
  }).then(response => {
    dispatch(fetchCompetitorsSuccess(response.data));
  });
};

export const deleteCompetitor = id => dispatch => {
  return fetch({
    method: 'delete',
    url: `${API_BASE_URL}/competitors/${id}`,
  }).then(response => {
    dispatch(deleteCompetitorSuccess(id));
  });
};

export const fetchCompetitor = id => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/competitors/${id}`,
  }).then(response => {
    dispatch(fetchCompetitorSuccess(response.data));
  });
};

export const fetchCompetitorBySlug = slug => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/competitors?slug=${slug}`,
  }).then(response => {
    dispatch(fetchCompetitorSuccess(response.data));
  });
};

export const createCompetitorIntegration = (id, data) => dispatch => {
  return fetch({
    method: 'post',
    url: `${API_BASE_URL}/competitors/${id}/integrations`,
    data,
  }).then(response => {
    dispatch(createCompetitorIntegrationSuccess(response.data));
  });
};

export const fetchCompetitorIntegrations = id => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/competitors/${id}/integrations`,
  }).then(response => {
    dispatch(fetchCompetitorIntegrationsSuccess(response.data));
  });
};

export const fetchCompetitorIntegrationsBySlug = slug => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/competitors/integrations?slug=${slug}`,
  }).then(response => {
    dispatch(fetchCompetitorIntegrationsSuccess(response.data));
  });
};

export const updateCompetitorIntegration =
  (id, competitorIntegrationId, settings) => dispatch => {
    return fetch({
      method: 'post',
      url: `${API_BASE_URL}/competitors/${id}/integrations/${competitorIntegrationId}`,
      data: { settings },
    }).then(response => {
      dispatch(createCompetitorIntegrationSuccess(response.data));
    });
  };

export const testCompetitorIntegration =
  (id, competitorIntegrationId) => dispatch => {
    return fetch({
      method: 'post',
      url: `${API_BASE_URL}/competitors/${id}/integrations/${competitorIntegrationId}/test`,
      data: {},
    });
  };

export const deleteNotificationChannel = id => dispatch => {
  return fetch({
    method: 'delete',
    url: `${API_BASE_URL}/notification_channels/${id}`,
  }).then(response => {
    dispatch(deleteNotificationChannelSuccess(id));
  });
};

export const fetchNotificationChannels = id => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/notification_channels`,
  }).then(response => {
    dispatch(fetchNotificationChannelsSuccess(response.data));
  });
};

export const fetchOrg = orgId => dispatch => {
  return fetch({
    method: 'get',
    url: `${API_BASE_URL}/orgs/${orgId}`,
  }).then(response => {
    dispatch(fetchOrgSuccess(response.data));
  });
};
