import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import { fetch, API_BASE_URL } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function Oauth(props) {
  const params = new URLSearchParams(props.location.search);
  const token = params.get('token');

  useEffect(() => {
    if (!token) return props.history.push(`/competitors`);
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/users/verify`,
      data: { token },
    }).then(response => {
      toast('Your email has been verified.');
      props.history.push(`/competitors`);
    });
  }, []);

  return <div>verify - {token}</div>;
}

export default connect(mapStateToProps, {})(Oauth);
