import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import CompetitorOverview from './CompetitorOverview';

import {
  fetchCompetitors,
  fetchCompetitorIntegrations,
} from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return { competitors: state.data.competitors };
};

function Home(props) {
  const { competitors = [] } = props;

  useEffect(() => {
    props.fetchCompetitors();
  }, []);

  return (
    <div>
      {competitors.map(competitor => {
        return <CompetitorOverview key={competitor.id} entity={competitor} />;
      })}
    </div>
  );
}

export default connect(mapStateToProps, {
  fetchCompetitors,
  fetchCompetitorIntegrations,
})(Home);
