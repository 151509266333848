import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { fetch, API_BASE_URL } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return { orgId: state.user.org_id };
};

function OrgInfo(props) {
  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');

  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');

  useEffect(() => {
    fetch({
      method: 'get',
      url: `${API_BASE_URL}/orgs/${props.orgId}`,
    }).then(response => {
      setLoading(false);
      setName(response.data.name);
    });
  }, []);

  const updateOrg = () => {
    setSaving(true);
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/orgs/update`,
      data: {
        orgId: props.orgId,
        name,
      },
    }).then(response => {
      setSaving(false);
      toast('Your account name has been updated.');
      // setLoading(false);
      // setName(response.data.name);
    });
  };

  const updatePassword = () => {
    setUpdating(true);
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/users/updatePassword`,
      data: {
        currentPass,
        newPass,
        confirmNewPass,
      },
    }).then(response => {
      setUpdating(false);
      setCurrentPass('');
      setNewPass('');
      setConfirmNewPass('');
      toast('Your password has been updated.');
    });
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={75} color="inherit" />
      </div>
    );

  return (
    <div>
      <h2
        style={{
          margin: '30px',
        }}
      >
        Account Settings
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            width: '90%',
          }}
        >
          <div
            style={{
              margin: '30px',
            }}
          >
            <div>Change the name of this account.</div>
            <div>
              <TextField
                required
                id="outlined-required"
                label="Name"
                defaultValue="Hello World"
                variant="outlined"
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  width: '300px',
                }}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={saving}
              onClick={updateOrg}
            >
              {saving ? (
                <span>Saving Name..</span>
              ) : (
                <span>Update Account Name</span>
              )}
            </Button>
          </div>
        </Paper>
        <Paper
          style={{
            width: '90%',
            marginTop: '30px',
            marginBottom: '30px',
          }}
        >
          <div
            style={{
              margin: '30px',
            }}
          >
            <div>Change your password.</div>
            <div>
              <TextField
                required
                id="outlined-required"
                label="Current Password"
                defaultValue="Hello World"
                variant="outlined"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '300px',
                }}
                value={currentPass}
                onChange={e => setCurrentPass(e.target.value)}
              />
            </div>
            <div>
              <TextField
                required
                id="outlined-required"
                label="New Password"
                defaultValue="Hello World"
                variant="outlined"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                  width: '300px',
                }}
                value={newPass}
                onChange={e => setNewPass(e.target.value)}
              />
            </div>
            <div>
              <TextField
                required
                id="outlined-required"
                label="Confirm New Password"
                defaultValue="Hello World"
                variant="outlined"
                style={{
                  marginTop: '10px',
                  marginBottom: '20px',
                  width: '300px',
                }}
                value={confirmNewPass}
                onChange={e => setConfirmNewPass(e.target.value)}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={saving}
              onClick={updatePassword}
            >
              {updating ? (
                <span>Updating Password..</span>
              ) : (
                <span>Update Password</span>
              )}
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(OrgInfo);
