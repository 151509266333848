import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { fetch, API_BASE_URL } from '../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return { orgId: state.user.org_id };
};

function InvoicesTable(props) {
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetch({
      method: 'get',
      url: `${API_BASE_URL}/billing/invoices`,
    }).then(response => {
      setLoading(false);
      setInvoices(response.data);
    });
  }, []);

  if (loading) return <>loading...</>;

  return (
    <div>
      <h2>Invoices</h2>
      <div>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Invoice ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map(invoice => (
                <TableRow key={invoice.id}>
                  <TableCell>{invoice.id}</TableCell>
                  <TableCell>{Date(invoice.created)}</TableCell>
                  <TableCell>{invoice.description}</TableCell>
                  <TableCell>{invoice.total}</TableCell>
                  <TableCell>{invoice.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(InvoicesTable);
