import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

import InvoicesTable from './InvoicesTable';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import CardSection from './CardSection';

import { fetch, API_BASE_URL, fetchOrg } from '../redux/actions';

const plans = [
  {
    name: 'Free',
    competitors: 'Competitor Limit: 1',
    maxFrequency: 'Minimum update frequency: 1 hour',
    price: '$0',
  },
  {
    name: 'Basic',
    competitors: 'Competitor Limit: 5',
    maxFrequency: 'Minimum update frequency: 30 minutes',
    price: '$5/mo',
    priceId: 'price_HKm3W5xIz5hMYU',
  },
  {
    name: 'Pro',
    competitors: 'Competitor Limit: 20',
    maxFrequency: 'Minimum update frequency: 15 hour',
    price: '$12/mo',
    priceId: 'price_HKm49qIES6bXB6',
  },
  {
    name: 'Business',
    competitors: 'Competitor Limit: None',
    maxFrequency: 'Minimum update frequency: 5 minutes',
    price: '$29/mo',
    priceId: 'price_HKm4qaL7gFPet0',
  },
];

const priceToPlan = plans.reduce(
  (acc, plan) => Object.assign(acc, { [plan.priceId]: plan.name }),
  {}
);
const planToPrice = plans.reduce(
  (acc, plan) => Object.assign(acc, { [plan.name]: plan.priceId }),
  {}
);

const mapStateToProps = (state, ownProps) => {
  const { user: { org_id: orgId } = {} } = state;
  const org = state.org && state.org.id === orgId && state.org;
  return { orgId, org };
};

function Billing(props) {
  const { org, orgId, fetchOrg } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(!org);
  // const [org, setOrg] = useState({});

  const currentPlan =
    priceToPlan[org && org.stripe_subscription_price_id] || 'Free';

  const [selectedPlan, setSelectedPlan] = useState(currentPlan);

  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {};

  useEffect(() => {
    if (!org) {
      fetchOrg(orgId).then(() => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    setSelectedPlan(currentPlan);
  }, [currentPlan]);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    const {
      id: paymentMethodId,
      card: { brand, last4 },
    } = payload.paymentMethod;
    const priceId = 'price_HKm3W5xIz5hMYU';

    fetch({
      method: 'post',
      url: `${API_BASE_URL}/create-subscription`,
      data: {
        paymentMethodId,
        priceId,
        orgId: props.orgId,
        brand,
        last4,
      },
    })
      .then(response => {
        return response.data;
      })
      // If the card is declined, display an error to the user.
      .then(result => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer.
          throw result;
        }
        return result;
      })
      // Normalize the result to contain the object returned by Stripe.
      // Add the addional details we need.
      .then(result => {
        return {
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscription: result,
        };
      })
      .then(result => {
        if (result.subscription.status === 'active') {
          // Change your UI to show a success message to your customer.
          // Call your backend to grant access to your service based on
          // `result.subscription.items.data[0].price.product` the customer subscribed to.
        }
      })
      .catch(error => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        console.error(error);
      });
  };

  const handleChangeSubscription = () => {
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/update-subscription`,
      data: {
        subscriptionId: org.stripe_subscription_id,
        priceId: planToPrice[selectedPlan],
      },
    }).then(() => {
      fetchOrg(orgId);
    });
  };

  const remove = pmid => {
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/removePaymentMethod`,
      data: {
        pmid,
      },
    }).then(() => {
      fetchOrg(orgId);
    });
  };

  const openStripePortal = () => {
    fetch({
      method: 'post',
      url: `${API_BASE_URL}/billingPortalSession`,
      data: {
        scid: org.stripe_customer_id,
      },
    }).then(session => {
      if (session.data && session.data.url) {
        window.location = session.data.url;
      }
    });
  };

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={75} color="inherit" />
      </div>
    );

  return (
    <div>
      <h2
        style={{
          margin: '30px',
        }}
      >
        Billing
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            width: '90%',
          }}
        >
          <div
            style={{
              margin: '30px',
            }}
          >
            <div>Current Plan: {currentPlan}</div>
            <button disabled={saving} onClick={() => setExpanded(true)}>
              Change Plan
            </button>
            <ExpansionPanel
              expanded={expanded}
              onChange={handleChange('panel1')}
            >
              <ExpansionPanelSummary
                aria-controls="panel1bh-content"
                className="exp-panel-summary"
              ></ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div>plans</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    {plans.map(plan => {
                      const cname =
                        selectedPlan === plan.name ? 'selected-plan' : '';
                      return (
                        <div
                          style={{
                            width: '22%',
                            border: '1px solid black',
                          }}
                          className={`billing-plan ${cname}`}
                          onClick={() => setSelectedPlan(plan.name)}
                        >
                          <div className="plan-name">{plan.name}</div>
                          <div className="plan-price">{plan.price}</div>
                          <div className="plan-details">
                            <ul>
                              <li>
                                <div className="plan-competitors">
                                  {plan.competitors}
                                </div>
                              </li>
                              <li>
                                <div className="plan-max-frequency">
                                  {plan.maxFrequency}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {!org.stripe_payment_method_id ? (
                    <>
                      <div
                        style={{
                          height: '100px',
                        }}
                      >
                        <CardSection />
                      </div>
                      <div>
                        <button onClick={handleSubmit}>Start Plan</button>
                      </div>
                    </>
                  ) : selectedPlan !== currentPlan ? (
                    <div>
                      <button onClick={handleChangeSubscription}>
                        Update Plan
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  <button onClick={() => setExpanded(false)}>Close</button>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Paper>
        {org.stripe_payment_method_id ? (
          <Paper
            style={{
              width: '90%',
              marginTop: '30px',
            }}
          >
            <div
              style={{
                margin: '30px',
              }}
            >
              <div>Payment Method</div>
              <div>{org.stripe_payment_method_brand}</div>
              <div>{org.stripe_payment_method_last_4}</div>
              {
                // <Button variant="contained" color="secondary" onClick={() => remove(org.stripe_payment_method_id)}>Remove</Button>
              }
            </div>
          </Paper>
        ) : (
          <></>
        )}
        <Paper
          style={{
            width: '90%',
            marginTop: '30px',
            marginBottom: '30px',
          }}
        >
          <div
            style={{
              margin: '30px',
            }}
          >
            <InvoicesTable />
          </div>
        </Paper>
        <Button variant="primary" onClick={openStripePortal}>
          Open Stripe Portal
        </Button>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, { fetchOrg })(Billing);
