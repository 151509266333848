import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { fetch, API_BASE_URL } from '../redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    minHeight: 350,
    maxHeight: 350,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const mapStateToProps = (state, ownProps) => {
  return {};
};

function CompetitorIntegrationOverview(props) {
  const classes = useStyles();
  const [integrationData, setIntegrationData] = useState([]);

  const { entity } = props;

  useEffect(() => {
    if (!integrationData.length) {
      fetch({
        method: 'get',
        url: `${API_BASE_URL}/competitors/${entity.competitor_id}/integrations/${entity.id}/data`,
      }).then(response => {
        setIntegrationData(response.data.data);
      });
    }
  }, [entity.id, entity.competitor_id]);

  return (
    <div
      style={{
        display: integrationData.length ? 'inherit' : 'none',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {integrationData.map(d => {
          console.log(d);
          if (d.integration_type === 'Twitter') {
            return (
              <div key={d.id}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      style={{
                        height: '100px',
                        overflow: 'hidden',
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {d.data.full_text || ''}
                    </Typography>
                  </CardContent>
                  {
                    // d.data.images.length ? (
                    //   <CardMedia
                    //     className={classes.media}
                    //     image={d.images[0].url}
                    //     title="Paella dish"
                    //   />
                    // ) : d.data.urls.length ? (
                    //   <>{d.urls[0].url}</>
                    // ) : (
                    //   <></>
                    // )
                  }
                  <CardActions>
                    <span>likes: {d.data.favorite_count}</span>
                    <span>retweets: {d.data.retweet_count}</span>
                    <a
                      href={`https://twitter.com/${d.data.user.name}/status/${d.data.id_str}`}
                      target="_blank"
                    >
                      VIEW
                    </a>
                  </CardActions>
                </Card>
              </div>
            );
          } else if (entity.integration === 'Instagram') {
            return (
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={d.src}
                  title="Paella dish"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {d.caption}
                  </Typography>
                </CardContent>
              </Card>
            );
          } else if (d.integration_type === 'Reddit') {
            return (
              <div key={d.id}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      style={{
                        height: '100px',
                        overflow: 'hidden',
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {d.data.title || ''}
                    </Typography>
                  </CardContent>
                  {
                    // d.data.images.length ? (
                    //   <CardMedia
                    //     className={classes.media}
                    //     image={d.images[0].url}
                    //     title="Paella dish"
                    //   />
                    // ) : d.data.urls.length ? (
                    //   <>{d.urls[0].url}</>
                    // ) : (
                    //   <></>
                    // )
                  }
                  <CardActions>
                    {/* <span>likes: {d.data.favorite_count}</span>
                    <span>retweets: {d.data.retweet_count}</span> */}
                    <a href={d.data.url} target="_blank">
                      VIEW
                    </a>
                  </CardActions>
                </Card>
              </div>
            );
          } else if (d.integration_type === 'AppStore') {
            return (
              <div key={d.id}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography
                      style={{
                        height: '100px',
                        overflow: 'hidden',
                      }}
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {d.data.releaseNotes || ''}
                    </Typography>
                  </CardContent>
                  {
                    // d.data.images.length ? (
                    //   <CardMedia
                    //     className={classes.media}
                    //     image={d.images[0].url}
                    //     title="Paella dish"
                    //   />
                    // ) : d.data.urls.length ? (
                    //   <>{d.urls[0].url}</>
                    // ) : (
                    //   <></>
                    // )
                  }
                  <CardActions>
                    {/* <span>likes: {d.data.favorite_count}</span>
                    <span>retweets: {d.data.retweet_count}</span> */}
                    <a href={d.data.url} target="_blank">
                      VIEW
                    </a>
                  </CardActions>
                </Card>
              </div>
            );
          } else
            return (
              <div key={d.id} style={{ maxWidth: '400px' }}>
                <div>{d.id}</div>
                <pre>{JSON.stringify(d, null, 2)}</pre>
              </div>
            );
        })}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, {})(CompetitorIntegrationOverview);
