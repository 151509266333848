import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { deleteCompetitor } from '../../redux/actions';

function Remove(props) {
  const { entity, deleteCompetitor } = props;

  const [value, setValue] = useState('');

  const remove = () => {
    deleteCompetitor(entity.id).then(() => {
      props.history.push(`/competitors`);
    });
  };

  const enabled = value.toLowerCase() === entity.name.toLowerCase();
  return (
    <Paper
      style={{
        width: '90%',
      }}
    >
      <div
        style={{
          margin: '30px',
        }}
      >
        <div>Remove {entity.name}</div>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <div>
            Removing this competitor will remove all connected integrations.
          </div>
          <div>
            Type <span style={{ fontWeight: 'bold' }}>{entity.name}</span> below
            and then click Remove
          </div>
          <TextField
            required
            id="outlined-required"
            label="Competitor Name"
            variant="outlined"
            style={{ marginTop: '20px', marginBottom: '20px' }}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <Button
              disabled={!enabled}
              variant="contained"
              color="secondary"
              onClick={() => remove()}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default connect(null, { deleteCompetitor })(Remove);
