import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import { Link } from 'react-router-dom';

import { fetch, API_BASE_URL, fetchOrg } from '../redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const mapStateToProps = (state, ownProps) => {
  console.dir(state);
  const { user: { org_id: orgId } = {} } = state;
  const org = state.org && state.org.id === orgId && state.org;
  return {
    orgId,
    org,
  };
};

function PositionedPopper(props) {
  const { orgId, org, fetchOrg } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const placement = 'right-end';
  const classes = useStyles();

  useEffect(() => {
    if (!org) {
      fetchOrg(orgId);
    }
  }, []);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setOpen((prev) => placement !== newPlacement || !prev);
    // setPlacement(newPlacement);
  };

  const handleClickAway = () => setAnchorEl(null);

  const handleBillingClick = () => {
    handleClickAway();
    if (org.stripe_customer_id) {
      fetch({
        method: 'post',
        url: `${API_BASE_URL}/billingPortalSession`,
        data: {
          scid: org.stripe_customer_id,
          redirect: window.location.href,
        },
      }).then(session => {
        if (session.data && session.data.url) {
          window.location = session.data.url;
        }
      });
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                  }}
                  to="/account"
                  onClick={handleClickAway}
                >
                  <Typography className={classes.typography}>
                    Account
                  </Typography>
                </Link>
                <div
                  key="sdafjhbashdkjf"
                  style={{ cursor: 'pointer' }}
                  onClick={handleBillingClick}
                >
                  <Typography className={classes.typography}>
                    Billing
                  </Typography>
                </div>
                {
                  // <Link
                  //   style={{ textDecoration: 'none', color: 'black' }}
                  //   to="/billing"
                  //   onClick={handleClickAway}
                  // >
                  //   <Typography className={classes.typography}>Billing</Typography>
                  // </Link>
                }
                <Link
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                  }}
                  to="/signout"
                  onClick={handleClickAway}
                >
                  <Typography className={classes.typography}>
                    Log Out
                  </Typography>
                </Link>
              </Paper>
            </Fade>
          )}
        </Popper>
        <AccountCircleOutlinedIcon
          onClick={handleClick}
          style={{ fontSize: 35 }}
        />
      </div>
    </ClickAwayListener>
  );
}

export default connect(mapStateToProps, { fetchOrg })(PositionedPopper);
