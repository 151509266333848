import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {};
};

function SiteTraffic(props) {
  return <div>SiteTraffic</div>;
}

export default connect(mapStateToProps)(SiteTraffic);
