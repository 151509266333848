export const HOME = '/';
export const EMPTY = '/';
export const ABOUT = '/about';
export const SIGNIN = '/signin';
export const SIGNUP = '/signup';
export const SIGNOUT = '/signout';
export const DASHBOARD = '/dashboard';
export const PRICING = '/pricing';
export const FEATURES = '/features';

export const HELP = '/help';
export const COMPETITORS = '/competitors';
export const CHANNELS = '/channels';
export const SETTINGS = '/settings';
export const ACCOUNT = '/account';
export const SERVICES = '/settings/services';
export const BILLING = '/billing';
export const BILLINGPLANS = '/billing/plans';
export const PAYMENT = '/settings/billing/payment';
export const PLAN = '/settings/billing/plan';
export const NOTIFICATIONS = '/notifications';

export const OAUTH = '/oauth';
export const VERIFY = '/verify';
